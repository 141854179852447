import React from 'react'

export default props => (
  <svg width="12" viewBox="0 0 12 7" {...props}>
    <defs>
      <path
        d="M9.9 1.3L6 5.2 2.1 1.3A.99.99 0 1 0 .7 2.7l4.593 4.593a1 1 0 0 0 1.414 0L11.3 2.7a.99.99 0 1 0-1.4-1.4z"
        id="arrow-bottom"
      />
    </defs>
    <use
      fill="currentColor"
      xlinkHref="#arrow-bottom"
      transform="translate(0 -1)"
      fillRule="evenodd"
    />
  </svg>
)
