import * as c from "../../constants";

const initialState = {
  isLoading: false,
  error: "",
  devices: [],
  interventions: [],
};

export default function devices(state = initialState, action) {
  switch (action.type) {
    case c.LIST_MEMBER_DEVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case c.LIST_MEMBER_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        isLoading: false,
      };
    case c.LIST_MEMBER_DEVICES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case c.SELECT_DEVICE_INTERVENTIONS:
      return {
        ...state,
        interventions: action.payload,
      };
    case c.RESET_SELECTED_DEVICE_INTERVENTIONS:
      return {
        ...state,
        interventions: [],
      };
    default:
      return state;
  }
}
