export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const RECEIVE_USER_LOG_IN_ERROR = "RECEIVE_USER_LOG_IN_ERROR";
export const USER_EMAIL_UPDATED = "USER_EMAIL_UPDATED";
export const HYDRATE_USER = "HYDRATE_USER";
export const REQUEST_MEMBERS = "REQUEST_MEMBERS";
export const RECEIVE_MEMBERS = "RECEIVE_MEMBERS";
export const SELECTED_MEMBER = "SELECTED_MEMBER";
export const REQUEST_AUTH_HEADERS = "REQUEST_AUTH_HEADERS";
export const RECEIVE_AUTH_HEADERS = "RECEIVE_AUTH_HEADERS";
export const CLEAR_SELECTED_MEMBER = "CLEAR_SELECTED_MEMBER";

export const MEMBERS_GET_ALL_SUCCESS = "MEMBERS_GET_ALL_SUCCESS";
export const MEMBERS_GET_ALL_FAILURE = "MEMBERS_GET_ALL_FAILURE";

export const RECEIVE_GUEST_MEMBERS = "RECEIVE_GUEST_MEMBERS";
export const RECEIVE_GUEST_USER_INFO = "RECEIVE_GUEST_USER_INFO";

export const RECEIVE_SELECTED_USER_ID = "RECEIVE_SELECTED_USER_ID";

export const ESI_SEARCH = "ESI_SEARCH";

export const CLEAR_DISPUTE_INFO = "CLEAR_DISPUTE_INFO";

export const UPDATE_LOADING_SEARCH_STATE = "UPDATE_LOADING_SEARCH_STATE";

export const SEARCH_PAGINATION_STARTED = "SEARCH_PAGINATION_STARTED";
export const SEARCH_PAGINATION_COMPLETE = "SEARCH_PAGINATION_COMPLETE";
export const INCREMENT_SEARCH_RESULTS_PAGE_COUNT =
  "INCREMENT_SEARCH_RESULTS_PAGE_COUNT";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const REQUEST_MEMBER_INFO = "REQUEST_MEMBER_INFO";
export const RECEIVE_MEMBER_USER_INFO = "RECEIVE_MEMBER_USER_INFO";
export const RECEIVE_MEMBER_METER_INFO = "RECEIVE_MEMBER_METER_INFO";
export const RECEIVE_RELATED_MEMBER_METER_INFO =
  "RECEIVE_RELATED_MEMBER_METER_INFO";

export const REQUEST_CHANGE_TO_ACCOUNT = "REQUEST_CHANGE_TO_ACCOUNT";
export const REQUEST_SET_TAX_EXEMPTION = "REQUEST_SET_TAX_EXEMPTION";
export const RECEIVE_USER_UPDATE = "RECEIVE_USER_UPDATE";
export const REQUEST_ADD_FUNDS = "REQUEST_ADD_FUNDS";
export const RECEIVE_ADD_FUNDS_CONF = "RECEIVE_ADD_FUNDS_CONF";
export const RECEIVE_ADD_METER_CONF = "RECEIVE_ADD_METER_CONF";
export const RECEIVE_APPLY_REFUND_CONF = "RECEIVE_APPLY_REFUND_CONF";
export const RECEIVE_CLOSE_ACCOUNT_CONF = "RECEIVE_CLOSE_ACCOUNT_CONF";
export const RECEIVE_BILLING_ACTIVATE = "RECEIVE_BILLING_ACTIVATE";
export const CLEAR_MEMBER_CONF_MSG = "CLEAR_MEMBER_CONF_MSG";
export const RECEIVE_MEMBER_UPDATE = "RECEIVE_MEMBER_UPDATE";
export const REQUEST_UPDATE_STATEMENT_DELIVERY =
  "REQUEST_UPDATE_STATEMENT_DELIVERY";
export const REQUEST_UPDATE_AUTOPAY = "REQUEST_UPDATE_AUTOPAY";
export const UPDATE_AUTO_PAY_CONF = "UPDATE_AUTO_PAY_CONF";
export const UPDATE_STATEMENT_DELIVERY_CONF = "UPDATE_STATEMENT_DELIVERY_CONF";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const RECIEVE_BILLING_MONTHLY_CHARGES =
  "RECIEVE_BILLING_MONTHLY_CHARGES";
export const REQUEST_BILLING_ADJUSTMENT = "REQUEST_BILLING_ADJUSTMENT";
export const RECEIVE_BILLING_ADJUSTMENT = "RECEIVE_BILLING_ADJUSTMENT";
export const REQUEST_BILLING_TRANSACTIONS = "REQUEST_BILLING_TRANSACTIONS";
export const RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS =
  "RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS";
export const RECEIVED_ALL_BILLING_TRANSACTIONS =
  "RECEIVED_ALL_BILLING_TRANSACTIONS";
export const SET_BILLING_FETCHING_STATE = "SET_BILLING_FETCHING_STATE";

export const BILLING_ELIGIBLE_STATEMENTS = "/billing/list_eligible_statements";
export const REQUEST_BILLING_ELIGIBLE_STATEMENTS =
  "REQUEST_BILLING_ELIGIBLE_STATEMENTS";
export const RECEIVE_BILLING_ELIGIBLE_STATEMENTS =
  "RECIEVE_BILLING_ELIGIBLE_STATEMENTS";

export const REQUEST_LMI_TYPES = "REQUEST_LMI_TYPES";
export const RECEIVE_LMI_TYPES = "RECEIVE_LMI_TYPES";
export const REQUEST_PAYMENT_CARDS = "REQUEST_PAYMENT_CARDS";
export const RECEIVE_PAYMENT_CARDS = "RECEIVE_PAYMENT_CARDS";
export const REQUEST_PAYMENT_CHARGES = "REQUEST_PAYMENT_CHARGES";
export const RECEIVE_PAYMENT_CHARGES = "RECEIVE_PAYMENT_CHARGES";
export const REQUEST_PAYMENT_REFUND = "REQUEST_PAYMENT_REFUND";
export const RECEIVE_PAYMENT_REFUND = "RECEIVE_PAYMENT_REFUND";
export const START_PAYMENT_REFUND = "START_PAYMENT_REFUND";
export const END_PAYMENT_REFUND = "END_PAYMENT_REFUND";
export const ADD_PAYMENT_CHARGES = "ADD_PAYMENT_CHARGES";
export const TOGGLE_CHARGE = "TOGGLE_CHARGE";
export const PAYMENTS_TOGGLE_CONFIRM_DELETE = "PAYMENTS_TOGGLE_CONFIRM_DELETE";
export const PAYMENTS_SET_CARD_DELETE = "PAYMENTS_SET_CARD_DELETE";
export const PAYMENTS_TOGGLE_CONFIRM_DEFAULT_CARD =
  "PAYMENTS_TOGGLE_CONFIRM_DEFAULT_CARD";
export const PAYMENTS_SET_DEFAULT_CARD = "PAYMENTS_SET_DEFAULT_CARD";
export const PAYMENTS_SET_ERROR = "PAYMENTS_SET_ERROR";
export const PAYMENTS_CLEAR_ERROR = "PAYMENTS_CLEAR_ERROR";
export const PAYMENTS_INITIALIZED = "PAYMENTS_INITIALIZED";
export const PAYMENTS_SET_START_DATE_FOR_FETCH =
  "PAYMENTS_SET_START_DATE_FOR_FETCH";
export const PAYMENTS_SET_END_DATE_FOR_FETCH =
  "PAYMENTS_SET_END_DATE_FOR_FETCH";

export const RECEIVE_STRIPE_ACCOUNT_ID = "RECEIVE_STRIPE_ACCOUNT_ID";

export const REQUEST_SAVINGS = "REQUEST_SAVINGS";
export const RECEIVE_SAVINGS = "RECEIVE_SAVINGS";

export const METER_LOOKUP_UPDATED = "METER_LOOKUP_UPDATED";
export const REQUEST_METER_LOOKUP = "REQUEST_METER_LOOKUP";
export const REQUEST_METER_LOOKUP_SUCCESS = "REQUEST_METER_LOOKUP_SUCCESS";
export const REQUEST_METER_LOOKUP_FAILURE = "REQUEST_METER_LOOKUP_FAILURE";
export const METER_LOOKUP_UPDATE_SUCCESS = "METER_LOOKUP_UPDATE_SUCCESS";
export const METER_LOOKUP_UPDATE_FAILURE = "METER_LOOKUP_UPDATE_FAILURE";
export const HIDE_METER_LOOKUP_SNACKBAR = "HIDE_METER_LOOKUP_SNACKBAR";

export const REQUEST_METERS = "REQUEST_METERS";
export const RECEIVE_METERS = "RECEIVE_METERS";

export const REQUEST_USAGE = "REQUEST_USAGE";
export const RECEIVE_USAGE = "RECEIVE_USAGE";

export const REQUEST_GENERATION = "REQUEST_GENERATION";
export const RECEIVE_GENERATION = "RECEIVE_GENERATION";

export const REQUEST_MONTHLY_USAGE = "REQUEST_MONTHLY_USAGE";
export const RECEIVE_MONTHLY_USAGE = "RECEIVE_MONTHLY_USAGE";

export const REQUEST_NET_METERING_BANK = "REQUEST_NET_METERING_BANK";
export const RECEIVE_NET_METERING_BANK = "RECEIVE_NET_METERING_BANK";
export const MEMBER_NOTES_GET_SUCCESS = "MEMBER_NOTES_GET_SUCCESS";
export const MEMBER_NOTES_GET_FAILURE = "MEMBER_NOTES_GET_FAILURE";

export const MEMBER_NOTE_CREATE_REQUEST = "MEMBER_NOTE_CREATE_REQUEST";
export const MEMBER_NOTE_UPDATE_REQUEST = "MEMBER_NOTE_UPDATE_REQUEST";
export const MEMBER_NOTE_CREATE_SUCCESS = "MEMBER_NOTES_CREATE_SUCCESS";
export const MEMBER_NOTE_UPDATE_SUCCESS = "MEMBER_NOTES_UPDATE_SUCCESS";
export const MEMBER_NOTE_CREATE_FAILURE = "MEMBER_NOTES_CREATE_FAILURE";
export const MEMBER_NOTE_UPDATE_FAILURE = "MEMBER_NOTES_UPDATE_FAILURE";
export const MEMBER_NOTE_CREATED = "MEMBER_NOTES_CREATED";
export const MEMBER_RESET_REQUEST = "MEMBER_RESET_REQUEST";
export const MEMBER_RESET_SUCCESS = "MEMBER_RESET_SUCCESS";
export const MEMBER_RESET_FAIL = "MEMBER_RESET_FAIL";

// API ENDPOINTS
export const MEMBER_NOTES_GET_URL = "/members/notes";

export const MEMBER_NOTE_CREATE_URL = "/members/note/create";
export const MEMBER_NOTE_UPDATE_URL = "/members/note/update";

export const MEMBER_NOTE_TAGS_URL = "/members/note/tags";
export const MEMBER_NOTE_TAGS_SUCCESS = "MEMBER_NOTE_TAGS_SUCCESS";
export const MEMBER_NOTE_TAGS_CREATE_URL = "/members/note/tag/create";
export const MEMBER_NOTE_TAGS_UPDATE_URL = "/members/note/tag/update";
export const MEMBER_NOTE_TAGS_DELETE_URL = "/members/note/tag/delete";

export const GET_USER_URL = "/users";
export const LOGIN = "/users/signin";
export const USER_SEARCH_URL = "/users/search";
export const USER_UPDATE = "/users/update";
export const USER_ACTIVATE = "/users/activate";
export const USER_DEACTIVATE = "/users/deactivate";
export const USER_ACCESS_URL = "/users/token/access";

export const SIGNUP_RESET_PASSWORD_URL = "/users/password/newcustomer";

export const MEMBER_SEARCH_URL = "/members/search";
export const MEMBER_GET_URL = "/members/get";
export const MEMBER_GET_ALL = "/members/get_all";
export const MEMBER_UPDATE = "/members/update";
export const MEMBER_CREATE = "/members/create";
export const MEMBER_ARCHIVE = "/members/archive";

export const PROTON_METER_URL = "/meter/get_meter";
export const ECI_METER_URL = "/eci/get_member_meter";
export const CS_METER_URL = "/community_solar/meter/get";
export const CS_METER_UPDATE = "/community_solar/meter/update";

export const MEMBER_GET_PRODUCT = "/members/product/get";
export const MEMBER_GET_PRODUCT_SUCCESS = "MEMBER_GET_PRODUCT_SUCCESS";
export const MEMBER_GET_PRODUCT_FAILTURE = "MEMBER_GET_PRODUCT_FAILTURE";

export const METER_GET_DEPOSITS = "/meter/get_meter_deposit_list";
export const METER_GET_DEPOSITS_SUCCESS = "METER_GET_DEPOSITS_SUCCESS";
export const METER_GET_DEPOSITS_FAILTURE = "METER_GET_DEPOSITS_FAILTURE";
export const METER_CAPTURE_DEPOSIT = "/meter/capture_deposit";
export const REQUEST_CAPTURE_DEPOSIT = "REQUEST_CAPTURE_DEPOSIT";
export const RECEIVE_CAPTURE_METER_DEPOSIT_CONF =
  "RECEIVE_CAPTURE_METER_DEPOSIT_CONF";

export const BILLING_GET_ACCOUNT_BALANCE = "/billing/account/balance";
export const BILLING_GET_ACCOUNT_TRANSACTIONS =
  "/billing/get_account_transactions";
export const BILLING_GET_ACCOUNT_BALANCE_SUCCESS =
  "BILLING_GET_ACCOUNT_BALANCE_SUCCESS";
export const BILLING_GET_ACCOUNT_BALANCE_FAILTURE =
  "BILLING_GET_ACCOUNT_BALANCE_FAILTURE";

export const BILLING_GET_ACCOUNT_TRANSACTION_SUCCESS =
  "BILLING_GET_ACCOUNT_TRANSACTION_SUCCESS";
export const BILLING_GET_ACCOUNT_TRANSACTION_FAILTURE =
  "BILLING_GET_ACCOUNT_TRANSACTION_FAILTURE";

export const GUEST_GET_ALL = "/griddyguest/get_all";

export const METER_OPEN_SNACKBAR = "METER_OPEN_SNACKBAR";
export const METER_CLOSE_SNACKBAR = "METER_CLOSE_SNACKBAR";
export const SET_METER_IS_PROCESSING = "SET_METER_IS_PROCESSING";

export const METER_SEARCH_URL = "/meter/get_meter";
export const METER_TRANSACTION_TYPES_URL = "/meter/get_transaction_type";
export const GUEST_METER_SEARCH_URL = "/griddyguest/get_meter";
export const ADD_METER = "/meter/meters";
export const METER_RATE_CLASSES = "/meter/griddy_rate_classes";
export const METER_UPDATE_RATE_CLASS = "/meter/update_grc";
export const METER_OVERRIDE_RATE_CLASS = "/meter/set_grc_override";
export const METER_REMOVE_OVERRIDE = "/meter/remove_grc_override";
export const METER_MONTHLY_AVG_USAGE = "/meter/get_amu";
export const METER_SET_TAX_EXEMPTION = "/meter/set_tax_exemption";
export const METER_UPDATE = "/meter/update";
export const METER_REFUND_DEPOSIT = "/meter/refund_deposit";

export const METER_TRANSACTIONS = "/meter/get_transaction_list";
export const METER_TRANSACTIONS_UPDATE_URL = "/meter/update_transaction";
export const METER_TRANSACTIONS_PROCESS_URL = "/meter/process_transaction";
export const METER_TRANSACTION_REGISTER_URL = "/meter/register_transaction";
export const METERE_TRANSACTION_CREATE_URL =
  "/meter/make_new_outbound_transaction";
export const METER_TRANSACTION_HISTORY_URL = "/meter/get_transaction_history";
export const METER_TRANSACTION_LOAD_TRANSACTIONS =
  "METER_TRANSACTION_LOAD_TRANSACTIONS";
export const METER_TRANSACTION_SET_SUCCESS_MESSAGE =
  "METER_TRANSACTION_SET_SUCCESS_MESSAGE";
export const METER_TRANSACTION_SET_ERROR_MESSAGE =
  "METER_TRANSACTION_SET_ERROR_MESSAGE";
export const METER_TRANSACTION_OPEN_SNACKBAR =
  "METER_TRANSACTION_OPEN_SNACKBAR";
export const METER_TRANSACTION_CLOSE_SNACKBAR =
  "METER_TRANSACTION_CLOSE_SNACKBAR";
export const METER_TRANSACTION_SELECT_TRANSACTION =
  "METER_TRANSACTION_SELECT_TRANSACTION";
export const METER_TRANSACTION_LOAD_DATA = "METER_TRANSACTION_LOAD_DATA";
export const METER_TRANSACTIONS_UPDATE = "METER_TRANSACTIONS_UPDATE";
export const METER_TRANSACTIONS_TYPE_UPDATE = "METER_TRANSACTIONS_TYPE_UPDATE";
export const METER_TRANSACTIONS_UPDATE_STATE =
  "METER_TRANSACTIONS_UPDATE_STATE";
export const METER_TRANSACTIONS_TOGGLE_PROCESSING =
  "METER_TRANSACTIONS_TOGGLE_PROCESSING";
export const METER_TRANSACTIONS_NEW_UPDATE = "METER_TRANSACTIONS_NEW_UPDATE";
export const METER_TRANSACTIONS_NEW_UPDATE_DETAIL =
  "METER_TRANSACTIONS_NEW_UPDATE_DETAIL";
export const METER_TRANSACTION_ADD_TRANSACTION =
  "METER_TRANSACTION_ADD_TRANSACTION";
export const METER_TRANSACTION_MERGE_SELECTED =
  "METER_TRANSACTION_MERGE_SELECTED";
export const METER_TRANSACTION_GET_USERS = "METER_TRANSACTION_GET_USERS";

export const BILLING_ACCOUNT = "/billing/account";
export const BILLING_ACCOUNTS = "/billing/accounts";
export const BILLING_REFUND = "/billing/refund";
export const BILLING_ADDFUNDS = "/billing/charge_payment";
export const BILLING_ONE_TIME_PAYMENT = "/billing/create_chase_payment_method";
export const BILLING_ONE_TIME_PAYMENT_ICHECK =
  "/billing/create_icheck_payment_method";
export const BILLING_ONE_TIME_PAYMENT_STRIPE =
  "/billing/payment_create_provider_account";
export const BILLING_ACTIVATE = "/billing/activate";
export const BILLING_DEACTIVATE = "/billing/deactivate";
export const BILLING_MONTHLY_CHARGES = "/billing/summary/monthly/charges";
export const BILLING_TRANSACTIONS = "/billing/get_billing_transactions";
export const BILLING_CREDIT = "/billing/credit";
export const BILLING_UPDATE_AUTOPAY = "/billing/update_autopay";
export const BILLING_UPDATE_ACCOUNT = "/billing/update";
export const BILLING_UPDATE_STATEMENT_DELIVERY =
  "/billing/update_statement_delivery";
export const GET_ACCEPTED_TYPE_SUBTYPE_PAIRS =
  "/billing/get_accepted_type_subtype_pairs";
export const BILLING_UPDATE_DISCONNECT_NOTICE_URL =
  "/billing/disconnect_notice/update";
export const DISCONNECT_NOTICE_UPDATE = "DISCONNECT_NOTICE_UPDATE";
export const BILLING_CREATE_STATEMENT = "/billing/initiate_statement";

export const METER_LOOKUP = "/meterLookup/get";
export const METER_SEARCH = "/meterLookup/search";
export const METER_LOOKUP_UPDATE = "/meterLookup/update";

export const GET_METER_LIST = "/meter/list";

export const GET_LMI_TYPES = "/community_solar/lmi_type/list";
export const PAYMENT_LIST_CHARGES = "/billing/list_payments";
export const PAYMENT_LIST_CARDS = "/billing/payment_list_payment_method";
export const PAYMENT_SAVE_PAYMENT = "/billing/save_payment";
export const PAYMENT_REFUND = "/payment/refund";
export const PAYMENT_ENROLL = "/payment/enroll";
export const PAYMENT_ADD_CARD = "/payment/add_card";
export const PAYMENT_REMOVE_CARD = "/billing/payment_remove_card";
export const PAYMENT_REFUND_URL = "/billing/refund_payment";
export const PAYMENT_CHARGEBACK_URL = "/billing/reverse_payment";
export const PAYMENT_SET_DEFAULT_CARD = "/billing/payment_set_default_card";

export const GET_USAGE = "/analytics/history";
export const GET_GENERATION = "/analytics/generation";

export const ANALYTICS_SAVINGS = "/analytics/savings";
export const GUEST_GET_USAGE = "/griddyguest/analytics/history";
export const GUEST_ANALYTICS_SAVINGS = "/griddyguest/analytics/savings";

export const TDSP_LOOKUP_DICT = {
  "007924772": "AEP CENTRAL",
  "007923311": "AEP NORTH",
  957877905: "CENTERPOINT ENERGY",
  "0088288574800": "NUECES",
  1039940674000: "ONCOR ELECTRIC DELIVERY",
  1052623364500: "SHARYLAND",
  105262336: "SHARYLAND MCALLEN",
  "007929441": "TNMP",
};

export const DNP_RNP_TRANSACTIONS_REQUEST_URL =
  "/meter/get_dnp_rnp_transactions";
export const DNP_FETCH_CANDIDATES_URL = "/billing/disconnect_notice/dnp_queue";
export const DNP_FETCH_DISCONNECT_NOTICE_URL = "/billing/disconnect_notice";
export const DNP_DISCONNECT_URL = "/meter/disconnect_non_pay";
export const DNP_RECONNECT_URL = "/meter/reconnect_non_pay";
export const DNP_LOAD_CANDIDATES = "DNP_LOAD_CANDIDATES";
export const DNP_LOAD_PENDING_TRANSACTIONS = "DNP_LOAD_PENDING_TRANSACTIONS";
export const DNP_UPDATE_CANDIDATE = "DNP_UPDATE_CANDIDATE";
export const DNP_TOGGLE_CANDIDATE_SELECT = "DNP_TOGGLE_CANDIDATE_SELECT";
export const DNP_TOGGLE_CANDIDATES_SELECT_ALL =
  "DNP_TOGGLE_CANDIDATES_SELECT_ALL";
export const DNP_TOGGLE_INPROGRESS = "DNP_TOGGLE_INPROGRESS";
export const DNP_TOGGLE_SUCCESS = "DNP_TOGGLE_SUCCESS";
export const DNP_SET_SUCCESS_MESSAGE = "DNP_SET_SUCCESS_MESSAGE";
export const DNP_SET_ERROR_MESSAGE = "DNP_SET_ERROR_MESSAGE";
export const DNP_SET_METER_ERROR = "DNP_SET_METER_ERROR";
export const DNP_CLEAR_ALL_ERRORS = "DNP_CLEAR_ALL_ERRORS";
export const DNP_TOGGLE_LOADING = "DNP_TOGGLE_LOADING";

export const DNP_RNP_LOAD_TRANSACTIONS = "DNP_RNP_LOAD_TRANSACTIONS";
export const DNP_RNP_UPDATE_FILTERS = "DNP_RNP_UPDATE_FILTERS";
export const DNP_RNP_CLOSE_SNACKBAR = "DNP_RNP_CLOSE_SNACKBAR";
export const DNP_RNP_OPEN_SNACKBAR = "DNP_RNP_OPEN_SNACKBAR";

export const ACCOUNT_FLAG_FETCH_URL = "/billing/accountflags";
export const ACCOUNT_FLAGS_MAIN_URL = "/billing/account";
export const ACCOUNT_FLAG_TYPES_LOAD = "ACCOUNT_FLAG_TYPES_LOAD";
export const ACCOUNT_FLAGS_LOAD = "ACCOUNT_FLAGS_LOAD";
export const ACCOUNT_FLAGS_ADD = "ACCOUNT_FLAGS_ADD";
export const ACCOUNT_FLAGS_DELETE = "ACCOUNT_FLAGS_DELETE";
export const ACCOUNT_FLAG_DNP_TOGGLE_LOADING =
  "ACCOUNT_FLAG_DNP_TOGGLE_LOADING";
export const ACCOUNT_FLAG_DISMISS_MESSAGE = "ACCOUNT_FLAG_DISMISS_MESSAGE";
export const ACCOUNT_FLAG_SET_SUCCESS = "ACCOUNT_FLAG_SET_SUCCESS";
export const ACCOUNT_FLAG_SET_ERROR = "ACCOUNT_FLAG_SET_ERROR";
export const ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW =
  "ACCOUNT_FLAG_TOGGLE_CATEGORY_VIEW";
export const ACCOUNT_FLAG_TOGGLE = "ACCOUNT_FLAG_TOGGLE";
export const ACCOUNT_FLAG_CANCEL_CHANGES = "ACCOUNT_FLAG_CANCEL_CHANGES";
export const ACCOUNT_FLAG_TOGGLE_LOADING = "ACCOUNT_FLAG_TOGGLE_LOADING";

export const TAX_EXCEMPTION_SUCCESS = "TAX_EXCEMPTION_SUCCESS";
export const TAX_EXCEMPTIONS = "/tax/get_exemption_types";
export const TAX_OPEN_SNACKBAR = "TAX_OPEN_SNACKBAR";
export const TAX_HIDE_SNACKBAR = "TAX_HIDE_SNACKBAR";

export const ITERABLE_OPEN_SNACKBAR = "ITERABLE_OPEN_SNACKBAR";
export const ITERABLE_CLOSE_SNACKBAR = "ITERABLE_CLOSE_SNACKBAR";
export const ITERABLE_UPDATE_STATE = "ITERABLE_UPDATE_STATE";
export const ITERABLE_REQUEST_SUCCESS = "ITERABLE_REQUEST_SUCCESS";

export const ITERABLE_CAMPAIGNS = "/users/iterable/campaigns";
export const ITERABLE_CHANNELS = "/users/iterable/channels";
export const ITERABLE_GET_EMAIL_TEMPLATE = "/users/iterable/email_template";
export const ITERABLE_VIEW_EMAIL_IN_BROWSER = "/users/iterable/email_view";
export const ITERABLE_USER_EVENTS = "/users/iterable/user_events";
export const ITERABLE_USER_INFO = "/users/iterable/user_info";

export const ITERABLE_UPDATE_USER_INFO = "ITERABLE_UPDATE_USER_INFO";
export const ITERABLE_UPDATE_USER_EVENTS = "ITERABLE_UPDATE_USER_EVENTS";
export const ITERABLE_UPDATE_USER_MESSAGES = "ITERABLE_UPDATE_USER_MESSAGES";
export const ITERABLE_SET_EMAIL_VIEW = "ITERABLE_SET_EMAIL_VIEW";
export const CLEAR_ITERABLE_DATA = "CLEAR_ITERABLE_DATA";
export const ITERABLE_SET_CAMPAIGN_COLLECTION =
  "ITERABLE_SET_CAMPAIGN_COLLECTION";
export const ITERABLE_SET_CHANNEL_COLLECTION =
  "ITERABLE_SET_CHANNEL_COLLECTION";
export const ITERABLE_ADD_TO_TEMPLATES = "ITERABLE_ADD_TO_TEMPLATES";
export const ITERABLE_CLEAR_USER_DATA = "ITERABLE_CLEAR_USER_DATA";

export const STATEMENT_OPEN_SNACKBAR = "STATEMENT_OPEN_SNACKBAR";
export const STATEMENT_CLOSE_SNACKBAR = "STATEMENT_CLOSE_SNACKBAR";

export const BILLING_STATEMENTS = "/billing/list_statements";
export const RECEIVE_BILLING_STATEMENTS = "RECIEVE_BILLING_STATEMENTS";
export const REQUEST_BILLING_STATEMENTS = "REQUEST_BILLING_STATEMENTS";

export const REQUEST_UPDATE_STATEMENT_STATUS =
  "REQUEST_UPDATE_STATEMENT_STATUS";
export const BILLING_UPDATE_STATEMENT_STATUS =
  "/billing/update_statement_status";
export const UPDATE_STATEMENT_STATUS_SUCCESS =
  "UPDATE_STATEMENT_STATUS_SUCCESS";
export const UPDATE_STATEMENT_STATUS_FAILURE =
  "UPDATE_STATEMENT_STATUS_FAILURE";
export const SET_STATEMENT_IS_PROCESSING = "SET_STATEMENT_IS_PROCESSING";

export const SET_SELECTED_STATEMENT_STATUS_FOR_FETCH =
  "SET_SELECTED_STATEMENT_STATUS_FOR_FETCH";

export const ENROLLMENT_SET_ERROR_MESSAGE = "ENROLLMENT_SET_ERROR_MESSAGE";
export const ENROLLMENT_SET_SUCCESS_MESSAGE = "ENROLLMENT_SET_SUCCESS_MESSAGE";
export const ENROLLMENT_CLOSE_SNACKBAR = "ENROLLMENT_CLOSE_SNACKBAR";
export const ENROLLMENT_UPDATE = "/enrollment/update";
export const ENROLLMENT_UPDATE_REQUEST = "ENROLLMENT_UPDATE_REQUEST";
export const ENROLLMENT_UPDATE_SUCCESS = "ENROLLMENT_UPDATE_SUCCESS";
export const ENROLLMENT_UPDATE_FAILURE = "ENROLLMENT_UPDATE_FAILURE";
export const ENROLLMENT_SEARCH_URL = "/enrollment/search";
export const ENROLLMENT_SEARCH_SUCCESS = "ENROLLMENT_SEARCH_SUCCESS";
export const ENROLLMENT_SEARCH_TOGGLE_PROCESSING =
  "ENROLLMENT_SEARCH_TOGGLE_PROCESSING";
export const ENROLLMENT_RESUBMIT_URL = "/enrollment/resubmit";
export const ENROLLMENT_RESUBMIT_SUCCESS = "ENROLLMENT_SEARCH_SUCCESS";
export const ENROLLMENT_CANCEL_URL = "/enrollment/cancel";
export const ENROLLMENT_UPDATE_NAME_URL = "/enrollment/update_name";
export const ENROLLMENT_HANDLE_ACTIONS_URL = "/enrollment/action";
export const ENROLLMENT_ADD_NOTE_URL = "/enrollment/notes/add";

export const TRANSFER_OF_SERVICE_OPEN_SNACKBAR =
  "TRANSFER_OF_SERVICE_OPEN_SNACKBAR";
export const SET_TRANSFER_OF_SERVICE_IS_PROCESSING =
  "SET_TRANSFER_OF_SERVICE_IS_PROCESSING";
export const TRANSFER_OF_SERVICE_CLOSE_SNACKBAR =
  "TRANSFER_OF_SERVICE_CLOSE_SNACKBAR";

export const RENEWAL_OFFERS_REQUEST = "RENEWAL_OFFERS_REQUEST";
export const RECEIVE_RENEWAL_OFFERS = "RECEIVE_RENEWAL_OFFERS";
export const GET_MEMBER_RENEWAL_OFFERS = "/members/product/renewable/get";
export const GET_MEMBER_PRODUCT_CHANGE_OFFERS =
  "/enrollment/get_tdsps_and_offers";

export const SELECT_RENEWAL_OFFER = "SELECT_RENEWAL_OFFER";
export const SUBMIT_RENEWAL_OFFER_FORM = "SUBMIT_RENEWAL_OFFER_FORM";
export const ENROLL_RENEWAL_OFFER = "/enrollment/update";

export const REQUEST_ENROLL_RENEWAL_OFFER = "REQUEST_ENROLL_RENEWAL_OFFER";

export const RENEWAL_CLOSE_SNACKBAR = "RENEWAL_CLOSE_SNACKBAR";
export const RENEWAL_OPEN_SNACKBAR = "RENEWAL_OPEN_SNACKBAR";
export const SET_RENEWAL_IS_PROCESSING = "SET_RENEWAL_IS_PROCESSING";
export const RENEWAL_RESET_FIELDS = "RENEWAL_RESET_FIELDS";

export const REPORTS_LIST_REPORTS = "/reports/list_report";
export const REPORTS_RUN_REPORT = "/reports/run_report";
export const REPORTS_GET_REPORTS_SUCCESS = "REPORTS_GET_REPORTS_SUCCESS";
export const REPORTS_RUN_REPORT_SUCCESS = "REPORTS_RUN_REPORT_SUCCESS";

export const LMI_APPROVAL_LIST_APPROVALS = "/community_solar/lmi_approval/list";
export const LMI_APPROVAL_LIST_APPROVALS_SUCCESS =
  "LMI_APPROVAL_LIST_APPROVALS_SUCCESS";
export const LMI_APPROVAL_GET_LMI_TYPES_SUCCESS =
  "LMI_APPROVAL_GET_LMI_TYPES_SUCCESS";
export const LMI_APPROVAL_GET_LMI_DOCUMENTS_SUCCESS =
  "LMI_APPROVAL_GET_LMI_DOCUMENTS_SUCCESS";
export const LMI_APPROVAL_GET_LMI_DETAILS_SUCCESS =
  "LMI_APPROVAL_GET_LMI_DETAILS_SUCCESS";

export const UNALLOCATED_PAYMENT_INITIALIZE = "UNALLOCATED_PAYMENT_INITIALIZE";
export const RECEIVE_UNALLOCATED_PAYMENT = "RECEIVE_UNALLOCATED_PAYMENT";
export const UNALLOCATED_PAYMENT_LIST = "/billing/list_unallocated_payment";
export const ALLOCATE_PAYMENT = "/billing/allocate_payment";
export const UNALLOCATED_PAYMENT_SUCCESS = "UNALLOCATED_PAYMENT_SUCCESS";
export const UNALLOCATED_PAYMENT_ERROR = "UNALLOCATED_PAYMENT_ERROR";

export const MEMBER_NOTIFY_LIST_NOTIFICATIONS = "/notification/list";
export const MEMBER_NOTIFY_PREVIEW_NOTIFICATION = "/notification/jobs/execute";
export const MEMBER_NOTIFY_GET_NOTIFICATIONS_SUCCESS =
  "MEMBER_NOTIFY_GET_NOTIFICATIONS_SUCCESS";

export const MEMBER_GET_NOTIFICATIONS_CONTENT_SUCCESS =
  "MEMBER_NOTIFY_GET_NOTIFICATIONS_CONTENT_SUCCESS";

export const MEMBER_CLEAR_NOTIFICATIONS_CONTENT_SUCCESS =
  "MEMBER_CLEAR_NOTIFICATIONS_CONTENT_SUCCESS";

export const PERMISSIONS_ADMIN_ACCESS = "admin_access";
export const PERMISSIONS_METER_TRANSACTION_MANAGER =
  "meter_transaction_manager";
export const PERMISSIONS_REFERRAL_CREDITS_APPROVAL =
  "referral_credits_approval";
export const PERMISIONS_METER_DNP_MANAGER = "meter_dnp_manager";
export const PERMISIONS_FILE_UPLOAD_MANAGER = "file_upload_manager";
export const PERMISIONS_LMI_APPROVER_MANAGER = "lmi_approver";
export const PERMISIONS_ENROLLMENT_MANAGER = "enrollment_manager";
export const PERMISIONS_CS_METER_MANAGER = "cs_meter_manager";
export const PERMISSIONS_PRODUCT_MANAGER = "product_manager";
export const PERMISSIONS_PROMO_MANAGER = "promo_manager";
export const PERMISSIONS_BILLING_MANAGER = "billing_manager";
export const PERMISSIONS_CUSTOMER_IMPERSONATE = "customer_impersonate";

export const LIST_REFERRAL_CREDITS = "/referrals/credits/list";
export const LIST_REFERRAL_CREDITS_SUMMARIES =
  "/referrals/credits/list_summaries";
export const APPROVE_REFERRAL_CREDITS = "/referrals/credits/approve";

export const REFERRALS_OPEN_SNACKBAR = "REFERRALS_OPEN_SNACKBAR";
export const REFERRALS_CLOSE_SNACKBAR = "REFERRALS_CLOSE_SNACKBAR";

export const REQUEST_REFERRAL_CREDITS_SUMMARIES =
  "REQUEST_REFERRAL_CREDITS_SUMMARIES";
export const RECEIVE_REFERRAL_CREDITS_SUMMARIES =
  "RECEIVE_REFERRAL_CREDITS_SUMMARIES";
export const REQUEST_REFERRAL_CREDITS = "REQUEST_REFERRAL_CREDITS";
export const RECEIVE_REFERRAL_CREDITS = "RECEIVE_REFERRAL_CREDITS";
export const REQUEST_APPROVE_REFERRAL_CREDITS =
  "REQUEST_APPROVE_REFERRAL_CREDITS";
export const APPROVE_REFERRAL_CREDITS_SUCCESS =
  "APPROVE_REFERRAL_CREDITS_SUCCESS";
export const APPROVE_REFERRAL_CREDITS_FAILURE =
  "APPROVE_REFERRAL_CREDITS_FAILURE";
export const LIST_REFERRALS = "/referrals/list";
export const RECEIVE_REFERRALS = "RECEIVE_REFERRALS";
export const REFERRALS_PRIMARY_ACCOUNT = "/referrals/get_user_primary_account";
export const RECEIVE_REFERRALS_PRIMARY_ACCOUNT = "RECEIVE_REFERRAL_ACCOUNT";
export const REFERRALS_REWARDS_BALANCE = "/rewards/balance";
export const RECEIVE_REFERRALS_REWARDS_BALANCE =
  "RECEIVE_REFERRALS_REWARDS_BALANCE";
export const UPDATE_REFERRALS_IS_PROCESSING = "UPDATE_REFERRALS_IS_PROCESSING";

export const PAYMENT_TYPE = {
  CC: "Credit_Card",
  ECP: "ECP",
};

export const ACCOUNT_TYPE = {
  savings: "Savings",
  checking: "Checking",
  commercialChecking: "Commercial Checking",
};
export const NOTIFICATIONS_LIST_NOTIFICATIONS = "/notification/jobs/list";
export const NOTIFICATIONS_PREVIEW_NOTIFICATION = "/notification/jobs/execute";
export const NOTIFICATIONS_GET_VIEW_URL = "/notification/get_view_url";
export const NOTIFICATIONS_GET_EVENTS_URL = "/notification/events/get";
export const NOTIFICATIONS_RESEND_URL = "/notification/send";
export const NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS =
  "NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_RUN_NOTIFICATION_SUCCESS =
  "NOTIFICATIONS_RUN_NOTIFICATION_SUCCESS";
export const NOTIFICATIONS_EXECUTE_NOTIFICATION_SUCCESS =
  "NOTIFICATIONS_EXECUTE_NOTIFICATION_SUCCESS";
export const NOTIFICATIONS_SET_ERROR_MESSAGE =
  "NOTIFICATIONS_SET_ERROR_MESSAGE";
export const NOTIFICATIONS_SET_SUCCESS_MESSAGE =
  "NOTIFICATIONS_SET_SUCCESS_MESSAGE";

export const USER_MANAGEMENT_GET_USERS_SUCCESS =
  "USER_MANAGEMENT_GET_USERS_SUCCESS";
export const USER_MANAGEMENT_RUN_SUCCESS = "USER_MANAGEMENT_RUN_SUCCESS";
export const USER_MANAGEMENT_SET_ERROR_MESSAGE =
  "USER_MANAGEMENT_SET_ERROR_MESSAGE";
export const USER_MANAGEMENT_SET_SUCCESS_MESSAGE =
  "USER_MANAGEMENT_SET_SUCCESS_MESSAGE";
export const USER_MANAGEMENT_GET_ROLES_SUCCESS =
  "USER_MANAGEMENT_GET_ROLES_SUCCESS";
export const USERS_MANAGEMENT_GET_USERS = "/users/list";
export const USERS_MANAGEMENT_GET_ROLES = "/users/roles";
export const USERS_MANAGEMENT_CREATE_USER = "/users/signup";
export const USERS_MANAGEMENT_UPDATE_ROLES = "/users/update_roles";
export const USERS_MANAGEMENT_RESET_PASSWORD = "/users/password/newcustomer";
export const PERMISSIONS_USER_MANAGER = "user_manager";

export const USAGE_GET_MONTHLY_USAGE = "/analytics/get_monthly_usage";
export const USAGE_GET_NET_METERING_BANK_CREDIT =
  "/analytics/get_net_metering_bank";

export const NOTIFICATIONS_CLOSE_SNACKBAR = "NOTIFICATIONS_CLOSE_SNACKBAR";
export const NOTIFICATIONS_OPEN_SNACKBAR = "NOTIFICATIONS_OPEN_SNACKBAR";
export const NOTIFICATIONS_IS_FETCHING = "NOTIFICATIONS_IS_FETCHING";

export const STATUS_CLOSE_SNACKBAR = "STATUS_CLOSE_SNACKBAR";
export const STATUS_ERROR_MESSAGE = "STATUS_ERROR_MESSAGE";
export const STATUS_SUCCESS_MESSAGE = "STATUS_SUCCESS_MESSAGE";
export const STATUS_INCREMENT_PROCESSING = "STATUS_INCREMENT_PROCESSING";
export const STATUS_DECREMENT_PROCESSING = "STATUS_DECREMENT_PROCESSING";

export const MEMBER_DPP_GET_FAILURE = "MEMBER_DPP_GET_FAILURE";
export const MEMBER_DPP_GET_SUCCESS = "MEMBER_DPP_GET_SUCCESS";
export const MEMBER_DPP_GET_URL = "/billing/get_dpps";
export const MEMBER_DPP_VALIDATE_URL = "/billing/validate_dpp_eligible";
export const MEMBER_DPP_CREATE_URL = "/billing/create_dpp";

export const MEMBER_GET_DOCUMENT_UPLOAD_URL = "/members/files/get_upload_url";
export const MEMBER_GET_DOCUMENT_DOWNLOAD_URL =
  "/members/files/get_download_url";
export const MEMBER_REGISTER_DOCUMENT_UPLOAD_URL =
  "/members/files/report_uploaded";
export const MEMBER_UPDATE_DOCUMENT = "/members/files/update";
export const MEMBER_LIST_DOCUMENTS_URL = "/members/files/list";
export const MEMBER_DELETE_DOCUMENT_URL = "/members/files/delete";
export const MEMBER_LIST_DOCUMENTS = "MEMBER_LIST_DOCUMENTS";

export const CSA_CREATE_CSA_URL = "/csa/create";
export const CSA_GET_CSA_URL = "/csa/get";
export const CSA_RECEIVE_CSA = "CSA_RECEIVE_CSA";
export const CSA_RECEIVE_USER = "CSA_RECEIVE_USER";
export const CSA_LIST_CSA_URL = "/csa/list";
export const CSA_LIST_CSA = "CSA_LIST_CSA";
export const CSA_LIST_MEMBERS_URL = "/csa/member/list";
export const CSA_RECEIVE_MEMBERS = "CSA_LIST_MEMBERS";
export const CSA_CREATE_MEMBER_URL = "/csa/member/create";
export const CSA_GET_PRODUCT_OFFERS = "CSA_GET_PRODUCT_OFFERS";
export const ENROLL_GET_DEPOSIT_URL = "/enrollment/getdeposit";

export const MEMBER_PRODUCT_UPDATE = "/members/product/update";
export const MEMBER_PRODUCT_REMOVE_SUCCESS = "MEMBER_PRODUCT_REMOVE_SUCCESS";
export const TDSP_LIST_RATE_CODES = "TDSP_LIST_RATE_CODES";
export const TDSP_LIST_RATE_CODES_URL = "/tdsp/tdsp_rate_codes";

export const PROMO_LIST_PROMO = "/rewards/get_promos";
export const PROMO_UPDATE_PROMO = "/rewards/update_promo";
export const PROMO_CLOSE_SNACKBAR = "PROMO_CLOSE_SNACKBAR";
export const PROMO_OPEN_SNACKBAR = "PROMO_OPEN_SNACKBAR";
export const PROMO_IS_FETCHING = "PROMO_IS_FETCHING";
export const PROMO_GET_PROMO_SUCCESS = "PROMO_GET_PROMO_SUCCESS";
export const PROMO_UPDATE_PROMO_SUCCESS = "PROMO_UPDATE_PROMO_SUCCESS";

export const MEMBER_LIST_LEVEL_BILLING_FAILURE =
  "MEMBER_LIST_LEVEL_BILLING_FAILURE";
export const MEMBER_LIST_LEVEL_BILLING_SUCCESS =
  "MEMBER_LIST_LEVEL_BILLING_SUCCESS";
export const MEMBER_LIST_LEVEL_BILLING_URL = "/billing/list_level_billings";
export const MEMBER_CREATE_LEVEL_BILLING_URL = "/billing/create_level_billing";

export const LIST_MEMBER_DEVICES = "/iot/devices/list";
export const LIST_MEMBER_DEVICES_REQUEST =
  "devices/LIST_MEMBER_DEVICES_REQUEST";
export const LIST_MEMBER_DEVICES_SUCCESS =
  "devices/LIST_MEMBER_DEVICES_SUCCESS";
export const LIST_MEMBER_DEVICES_FAILURE =
  "devices/LIST_MEMBER_DEVICES_FAILURE";

export const SELECT_DEVICE_INTERVENTIONS =
  "devices/SELECT_DEVICE_INTERVENTIONS";

export const RESET_SELECTED_DEVICE_INTERVENTIONS =
  "devices/RESET_SELECTED_DEVICE_INTERVENTIONS";
