import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import NotesTable from "./NotesTable.js";
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
    },
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  noteCardsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
}));

const UserNotes = (props) => {
  const {
    member,
    notes,
    noteTags,
    onFetchUserIDWithFullMemberInfoNeeded,
    onfetchFullMemberInfoNeeded,
    onGetNoteTags,
  } = props;

  const classes = useStyles();
  const availableTags = noteTags.tags;
  const [noteCards, setNoteCards] = useState([]);
  const [seeAllNotes, setSeeAllNotes] = useState(true);

  useEffect(() => {
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(props.params.memberId);
    } else {
      onfetchFullMemberInfoNeeded(props.member);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(availableTags).length === 0 && onGetNoteTags !== undefined) {
      onGetNoteTags();
    }
  }, [onGetNoteTags]);


  useEffect(() => {
    let selectedNotes = Array.from(notes);
    if (!seeAllNotes) {
      const mID = member.get("memberID");
      selectedNotes = selectedNotes.filter((note) => note.get("memberID") === mID);
    }
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    selectedNotes = selectedNotes.sort((a, b) => new Date(b.get("created")) - new Date(a.get("created")));

    setNoteCards(selectedNotes);
  }, [member, notes, seeAllNotes]);

  return (
    <div className={classes.root}>
      <h1 id="page-title">User Notes</h1>
      <div>
        <FormControlLabel
          title="Show notes for all accounts of this user"
          control={<Switch checked={seeAllNotes} onChange={() => setSeeAllNotes(!seeAllNotes)} />}
          label="All Account Notes"
        />

      </div>
      <div className={classes.noteCardsContainer}>
        <NotesTable notesData={noteCards} availableTags={availableTags} />
      </div>
    </div>
  );
};

UserNotes.propTypes = {
  member: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default UserNotes;